<template>
<div class="common">
    <div class="commonTop flex flex-ai-c flex-jc-sb">
        <div class="name flex flex-ai-c">{{$root.userInfo.ext_company_name}}{{$config.websiteTitle}}</div>
        <div class="" style="margin-right:auto;margin-left:20px">
            {{$root.userInfo.ext_time.date}} {{$root.userInfo.ext_time.week_no}}
        </div>
        <div class="userbox flex flex-ai-c flex-jc-sb">
            <el-dropdown trigger="hover" class="msg" style="cursor: pointer;margin-right:100px">
                <span class="el-dropdown-link">
                    <i class="el-icon-question"></i>
                    <span style="margin-left:5px">技术支持</span>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <div class="flex flex-column flex-ai-c flex-jc-sa" v-if="$root.userInfo.service">
                        <p style="padding:0 10px;text-align:center;line-height:1.5;color:#333;font-family:微软雅黑;">
                            客服电话：{{$root.userInfo.service.mobile}}<br>
                            或者扫描下方二维码直达客服微信：
                        </p>
                        <img :src="this.domain+$root.userInfo.service.qrcode" style="width: 200px;padding: 10px;box-sizing: border-box;">
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="user">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link flex flex-ai-c">
                        {{$root.userInfo.realname}}<i class="el-icon-arrow-down" style="margin-left:5px"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <!-- <el-dropdown-item v-if="$root.userInfo.admin_is==1" icon="el-icon-monitor" @click.native="goover()">进入后台</el-dropdown-item> -->
                        <el-dropdown-item icon="el-icon-s-unfold" @click.native="logOut()">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
    </div>

    <div class="commonLeft" :style="{'width':isCollapse?'64px':'150px'}">
        <div class="nav">
            <el-aside>
                <el-menu background-color="#191A23" :default-active="$route.path" text-color="rgba(255, 255, 255, 0.7)" :router="true" active-text-color="#fff" :collapse="isCollapse" style="height:calc( 100% - 145px )">
                    <template v-for="nav in navList">
                        <el-menu-item :index="nav.path" :key="nav.name" @click="navTo(nav.path)">
                            <i :class="nav.icon"></i>
                            <span slot="title">
                                {{nav.name }}
                            </span>
                        </el-menu-item>
                    </template>
                    <p style="height:50px;background:#191A23"></p>
                </el-menu>

                <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'150px'}">
                    <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                </div>
            </el-aside>
        </div>
    </div>
    <div class="commonRight" :style="{'left':isCollapse?'64px':'150px'}">
        <router-view :isCollapse="isCollapse" />
    </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
    data: function() {
        return {
            count: '', //消息通知数量
            search: '',
            defaultActive: '1',

            isCollapse: false,

            navList: [{
                name: '商户管理',
                path: '/headquartersMerchant',
                icon: 'el-icon-s-custom',
            },{
				name: '收费类别',
                path: '/headquartersCategory',
                icon: 'el-icon-s-ticket',
			},{
				name: '人员管理',
                path: '/headquartersPeople',
                icon: 'el-icon-user',
			},{
				name: '更新日志',
                path: '/headquartersUpdateDate',
                icon: 'el-icon-bangzhu',
			}],
        }
    },
    //导航守卫
    beforeRouteEnter(to, from, next) {
        let _token = window.sessionStorage.getItem('_token');
        Promise.all([
            axios.post(window.domain + '/Login/state', {
                id: sessionStorage.getItem('userId')
            }, {
                headers: {
                    'content-type': 'application/json',
                    'X-CSRF-TOKEN': _token
                }
            }),

        ]).then(result => next(vm => {
            if (result[0].data.code == 200) {
                vm.$root.userInfo = result[0].data.data;
            };
        }))
    },
    methods: {
        //nav跳转
        navTo: function(path) {
            this.$router.push(path);
        },
        handleCollapse: function() {
            this.isCollapse = !this.isCollapse;
        },
        logOut: function() {
            this.http.post('/login/logout').then(() => {
                this.$router.push('/');
                sessionStorage.clear();
            })
        },
        //进入后台
        goover() {
            this.$router.push('/organization')
        }
    },
}
</script>
